<template>
  <NuxtLayout name="default">
    <LcTabs
      v-bind="{ tabs, value }"
      adaptive-height
      :render-only-visible="false"
      trigger="manual"
      @update:value="navigateToTab"
    >
      <template #tab-panel="{ tab }">
        <NuxtPage :name="tab.name" />
      </template>
    </LcTabs>
  </NuxtLayout>
</template>

<script lang="ts" setup>
const router = useRouter()
const { activeTabs: tabs } = await useMyOrders()

const value = computed(() => String(router.currentRoute.value.name))

const navigateToTab = async (name?: string) => {
  const nextTab = tabs.value.find((tab) => tab.name === name)
  if (!nextTab) return

  await navigateTo(nextTab.data.path, { replace: true })
}
</script>
